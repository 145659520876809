var l = Object.defineProperty;
var o = (i, e, t) => e in i ? l(i, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : i[e] = t;
var n = (i, e, t) => o(i, typeof e != "symbol" ? e + "" : e, t);
import { ucFirst as a, toFriendly as c } from "@vgno/utils";
class p extends HTMLElement {
  constructor() {
    super();
    n(this, "interval", null);
    n(this, "timeElement", null);
  }
  update(t) {
    if (!this.timeElement)
      throw new Error("No time element found");
    const s = this.timeElement.dateTime;
    if (!s)
      throw new Error("No timestamp attribute found");
    const r = new Date(s).getTime(), m = a(
      c(r, void 0, t)
    );
    if (this.timeElement.querySelector("span")) {
      this.timeElement.querySelector("span").textContent = m;
      return;
    }
    this.timeElement.textContent = m;
  }
  connectedCallback() {
    this.timeElement = this.querySelector("time");
    const t = this.options;
    this.update(t), this.interval = setInterval(() => this.update(t), 1e3);
  }
  disconnectedCallback() {
    this.interval && clearInterval(this.interval);
  }
  get options() {
    const { options: t } = this.dataset;
    return t ? JSON.parse(t) : {};
  }
}
function h() {
  customElements.get("friendly-timestamp") || customElements.define("friendly-timestamp", p);
}
export {
  p as FriendlyTimestampElement,
  h as registerFriendlyTimestampElement
};
